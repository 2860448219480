import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Header, Footer, ApplyFooter } from "@/components";
import "./markdown.css";
import { css, cx } from "@emotion/css";
import { Link, useIntl } from "gatsby-plugin-intl";

const NewsDetail = (props) => {
  const { data = {} } = props;
  const { banner = {}, markdownRemark = {} } = data;
  const { previous, next } = data;
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTimeout(() => {
      const title = document.title;
      if (title) {
        const titleArr = title.split(" - ");
        if (titleArr.length === 2 && titleArr[0].includes("menu.news.detail")) {
          titleArr[0] = markdownRemark.frontmatter?.title;
          document.title = titleArr.toString().replace(",", " - ");
        }
      }
    }, 0);
    const content = document.querySelector("#gatsby-focus-wrapper");
    content.scrollTo(0, 0);
  }, [markdownRemark]);

  return (
    <div className="news-detail min-h-screen bg-whiter relative flex flex-col">
      <Header {...props} />
      <main className="flex-auto">
        {banner?.publicURL && (
          <div
            className="w-full h-44 md:h-56 xl:h-64 2xl:h-80 bg-no-repeat bg-center bg-cover bg-full"
            style={{
              backgroundImage: `url(${banner.publicURL})`,
            }}
          ></div>
        )}
        <div className="w-full">
          <div className="max-w-7xl mx-auto py-8 px-4 2xl:px-0 md:py-12 lg:py-16 xl:py-24">
            <div className="max-w-4xl px-4 md:px-6 lg:px-8 mx-auto">
              <h1 className="text-4xl font-extrabold mb-6">{markdownRemark.frontmatter?.title}</h1>
              <p className="text-gray-700 mb-6">
                {markdownRemark.frontmatter?.date} {markdownRemark.frontmatter?.author}
              </p>
              <section
                className={cx(
                  "markdown-body",
                  css`
                    background: transparent !important;
                    ul {
                      list-style: disc;
                    }
                    ol {
                      list-style: decimal;
                    }
                    ul,
                    ol {
                      li {
                        margin-bottom: 8px;
                      }
                    }
                    h2 {
                      font-size: 1.7em;
                      border-bottom: none;
                    }
                    h3 {
                      font-size: 1.5em;
                    }
                    h4 {
                      font-size: 1.3em;
                    }
                    h2,
                    h3,
                    h4 {
                      margin-bottom: 18px;
                    }
                    .gatsby-resp-image-wrapper {
                      max-width: 100% !important;
                    }
                  `
                )}
                dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
                itemProp="articleBody"
              />
            </div>
          </div>
          <div className="flex justify-center flex-col md:flex-row py-2 md:py-4 lg:py-8 gap-2 md:gap-4 lg:gap-6">
            {previous && (
              <button
                className="p-2 border-gray-200 border-2 border-solid rounded font-bold text-lg text-gray-500"
                disabled={previous === null}
              >
                <Link to={"/news/detail/" + previous?.frontmatter?.title}>
                  {formatMessage({ id: "page.news.prev" })} {previous?.frontmatter?.title}
                </Link>
              </button>
            )}
            {next && (
              <button
                className="p-2 border-gray-200 border-2 border-solid rounded font-bold text-lg text-gray-500"
                disabled={next === null}
              >
                <Link to={"/news/detail/" + next?.frontmatter?.title}>
                  {formatMessage({ id: "page.news.next" })} {next?.frontmatter?.title}
                </Link>
              </button>
            )}
          </div>
        </div>
      </main>
      <Footer>
        <ApplyFooter type="1" />
      </Footer>
    </div>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    banner: file(relativeDirectory: { eq: "banners" }, name: { eq: "about" }) {
      id
      publicURL
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        author
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

export default NewsDetail;
